<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Refund Payment
    </template>
    <template slot="body">
      <template v-if="refunds.length">
        <p><b>REFUNDS</b></p>
        <table class="table w-100">
          <tr>
            <th>DATE</th>
            <th>TOTAL</th>
            <th>METHOD</th>
          </tr>
          <tr
            v-for="r of refunds"
            :key="r.package_payment_id"
          >
            <td>{{ dateFormat(r.final_payment_date) }}</td>
            <td>{{ moneyFormat(r.total) }}</td>
            <td>{{ r.payment_method.name }}</td>
          </tr>
        </table>

        <hr>
      </template>

      <PaymentMethodsSelect
        v-model="form.payment_method_id"
        icon="euro"
      />
      <form-date
        v-model="form.date"
        label="Date"
      />
      <form-number
        v-model="form.total"
        label="Total"
        icon="euro"
      />
      <form-textarea
        v-model="form.comment"
        label="Comment"
      />
    </template>
    <template slot="footer">
      <md-button
        class="md-primary"
        @click="refund"
      >
        Refund
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormNumber,
  FormDate,
  FormTextarea,
} from '@/components/Inputs';
import {
  PaymentMethodsSelect,
} from '@/components/Inputs/selects';
import moment from 'moment';

export default {
  components: {
    PaymentMethodsSelect,
    FormNumber,
    FormDate,
    FormTextarea,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        total: 0,
        date: moment(),
        payment_method_id: null,
        comment: null,
      },
      refunds: [],
    };
  },
  mounted() {
    this.form.total = this.item.total;

    this.getRefunds();
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    refund() {
      this.request(`${this.$API.SCHEDULED_PAYMENTS + this.item.package_payment_id}/refund`, 'post', this.form, () => {
        this.fireSuccess('Payment Refunded successfully');
        this.$emit('save');
        this.close();
      });
    },
    getRefunds() {
      this.request(`packages/${this.item.package_id}/refunds`, 'get', {}, ({ data }) => {
        this.refunds = data;
      });
    },
  },
};
</script>
<style></style>
