<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item">
            <PaymentStatusesSelect
              v-model="filters.status"
              multiple
            />
          </div>
          <div class="md-layout-item">
            <PaymentMethodsSelect
              v-model="filters.payment_method_id"
              all-option="All"
              icon="credit_card"
              with-deleted
            />
          </div>
          <div class="md-layout-item">
            <FormSelect
              v-model="filters.refunded"
              icon="euro"
              label="Show Refunded Payment"
              :options="[
                { id: 'all', name: 'ALL' },
                { id: 'false', name: 'NO' },
                { id: 'true', name: 'YES' },
              ]"
            />
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
            <md-button
              class="md-primary md-sm w-100"
              @click="onDownload"
            >
              Download XLS
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getPayments"
              @onApprovePayment="onApprovePayment"
              @onDenyPayment="onDenyPayment"
              @onEdit="onEdit"
              @onShowPaymentDetails="onShowPaymentDetails"
              @onShowDetails="onShowDetails"
              @onDelete="onDelete"
              @onDownloadInvoice="onDownloadInvoice"
              @onDownloadRefundConf="onDownloadRefundConf"
              @onRefund="onRefund"
              @onToProfile="onToProfile"
              @onCreateButton="onCreateButton"
              @onResendReceipt="onResendReceipt"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <approve-payment
      v-if="approvePaymentDetails"
      :item="approvePaymentDetails"
      @close="approvePaymentDetails = null"
      @onSave="$refs.vtable.init"
    />

    <payment-details
      v-if="selPaymentDetails"
      :item="selPaymentDetails"
      @close="selPaymentDetails = null"
      @onSave="$refs.vtable.init"
    />

    <refund-payment
      v-if="toRefund"
      :item="toRefund"
      @close="toRefund = null"
      @onSave="$refs.vtable.init"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import Swal from 'sweetalert2';
import { FormSelect } from '@/components/Inputs';
import { PaymentStatusesSelect, PaymentMethodsSelect } from '@/components/Inputs/selects';
import moment from 'moment';
import downloadFile from '@/utils/downloadFile';
import ApprovePayment from './ApprovePayment.vue';
import PaymentDetails from './PaymentDetails.vue';
import RefundPayment from './RefundPayment.vue';

export default {
  components: {
    VueTable,
    PaymentStatusesSelect,
    PaymentMethodsSelect,
    ApprovePayment,
    RefundPayment,
    PaymentDetails,
    FormSelect,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'package_payment_id',
            mask: 'Payment ID',
            no_format: true,
            sortable: true,
          },
          {
            title: 'package.programme.programme_id',
            mask: 'Programme ID',
            no_format: true,
            sortable: false,
          },
          {
            title: 'payment_date',
            mask: 'expected date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'final_payment_date',
            mask: 'payment date',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'payment_method.name',
            mask: 'payment method',
            sortable: true,
          },
          {
            title: 'package.programme.student.student_number',
            mask: 'student no.',
            sortable: false,
          },
          {
            title: 'package.programme.student.first_name',
            mask: 'given name',
            sortable: false,
          },
          {
            title: 'package.programme.student.last_name',
            mask: 'surname',
            sortable: false,
          },
          {
            title: 'total',
            mask: 'amount',
            pre: '€',
            sortable: true,
          },
          {
            title: 'status',
            sortable: true,
          },
          {
            title: 'proof_of_payment_id',
            mask: 'Has Proof',
            sortable: false,
            callback: (attr) => (attr !== '-' ? 'YES' : 'NO'),
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Go To Student Profile',
            callback: 'onToProfile',
            icon: 'person',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Re-send Receipt',
            callback: 'onResendReceipt',
            icon: 'mail',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Refund Payment',
            callback: 'onRefund',
            icon: 'money_off',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Approve Payment',
            callback: 'onApprovePayment',
            icon: 'thumb_up_alt',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Deny Payment',
            callback: 'onDenyPayment',
            icon: 'thumb_down_alt',
          },
          {
            buttonClass: 'md-info',
            tooltip: 'Show Details',
            callback: 'onShowPaymentDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Package Details',
            callback: 'onShowDetails',
            icon: 'list',
          },
          {
            buttonClass: 'md-success',
            tooltip: 'Download Invoice',
            callback: 'onDownloadInvoice',
            icon: 'get_app',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Download Refund Confirmation',
            callback: 'onDownloadRefundConf',
            icon: 'get_app',
          },
          {
            buttonClass: 'md-primary',
            tooltip: 'Send Payment Button',
            callback: 'onCreateButton',
            icon: 'forward_to_inbox',
          },
        ],
        values: {},
      },
      filters: {
        status: ['Unpaid'],
        payment_method_id: 'all',
        refunded: 'all',
      },
      selPaymentDetails: null,
      approvePaymentDetails: null,
      toRefund: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getPayments(params) {
      this.request(this.$API.SCHEDULED_PAYMENTS, 'get', {
        ...params,
        ...this.filters,
      }, ({ data }) => {
        const aux = data;
        aux.data.map((x) => {
          const a = x;
          a.onCreateButton = this.onlinePaymentsActive && ['Unpaid', 'Overdue'].includes(x.status);
          a.onDenyPayment = ['Unpaid', 'Overdue'].includes(x.status);
          a.onApprovePayment = ['Unpaid', 'Overdue'].includes(x.status);
          a.onShowPaymentDetails = x.status === 'Paid';
          a.onResendReceipt = x.status === 'Paid';
          a.onRefund = x.status === 'Paid' && parseFloat(x.total) > 0;
          a.onDownloadRefundConf = x.status === 'Paid' && parseFloat(x.total) <= 0;
          return a;
        });
        this.vTable.values = aux;
      });
    },
    onCreateButton(item) {
      this.fireConfirm('You are about to send an email', 'Are you sure?').then(() => {
        this.request(`payment/${item.package_payment_id}/send_button`, 'post', null, () => {
          this.$refs.vtable.init();
          this.fireSuccess('Button created succesfully, an Email has been sent to the student.');
        });
      });
    },
    onApprovePayment(item) {
      this.approvePaymentDetails = item;
    },
    onShowPaymentDetails(item) {
      this.selPaymentDetails = item;
    },
    onRefund(item) {
      this.toRefund = item;
    },
    onDenyPayment(item) {
      this.fireConfirm(
        'Denying Payment',
        'Are you sure you want to deny this payment?',
      ).then(() => {
        this.request(`${this.$API.SCHEDULED_PAYMENTS + item.package_payment_id}/deny`, 'post', null, () => {
          this.$refs.vtable.init();
          this.fireSuccess('Payment denied successfully!');
        });
      });
    },
    onEdit() {
      Swal.fire({
        title: 'Add bank fee',
        input: 'number',
        inputValue: this.bankFee,
        showCancelButton: true,
        inputValidator: (value) => {
          if (value <= 0) {
            return 'The value has to be greater than 1!';
          }
          return 0;
        },
      }).then(() => {
        this.fireSuccess('Bank fee added successfully');
      });
    },
    onDelete() {
      this.fireConfirm(
        'Deleting Payment',
        'Are you sure you want to delete this payment?',
      ).then(() => {
        this.fireSuccess('Payment deleted successfully!');
      });
    },
    onToProfile(item, isMiddleClick) {
      this.openLink({
        name: 'StudentResume',
        params: { id: item.package.programme.student_id },
      }, isMiddleClick);
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'PackagePayments', params: { id: item.package_id } }, isMiddleClick);
    },
    onDownloadInvoice(item) {
      this.request(this.$API.SCHEDULED_PAYMENTS + item.package_payment_id, 'download', null, ({ data }) => {
        const filename = `${item.package.programme.student.student_number}_${moment(item.payment_date).format('DD-MM-YYYY')}.pdf`;
        downloadFile(data, filename);
      });
    },
    onResendReceipt(item) {
      this.fireModal({
        title: 'Re-send Payment Receipt',
        html: 'Please fill input with receiver Email. <br> You can add multiple destinations separated with ";"',
        input: 'text',
        inputPlaceholder: 'Receiver Email',
        inputValue: item.package.programme.student.email,
      })
        .then((a) => {
          if (a.value) {
            this.request(`${this.$API.SCHEDULED_PAYMENTS + item.package_payment_id}/resend_receipt`, 'post', {
              email: a.value,
            }, () => {
              this.fireSuccess('Payment Receipt sent successfully!');
            });
          }
        });
    },
    onDownloadRefundConf(item) {
      this.request(`${this.$API.SCHEDULED_PAYMENTS + item.package_payment_id}/refund_confirmation`, 'download', null, ({ data }) => {
        const filename = `${item.package.programme.student.student_number}_${moment(item.payment_date).format('DD-MM-YYYY')}_refund.pdf`;
        downloadFile(data, filename);
      });
    },

    onFilter() {
      this.$refs.vtable.init();
    },
    onDownload() {
      this.loading = true;
      this.request(`${this.$API.SCHEDULED_PAYMENTS}download`, 'download', { ...this.filters }, ({ data }) => {
        const filename = 'scheduled_payments.xlsx';
        downloadFile(data, filename);
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
</style>
